
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { Box, Text, TextLink } from '@hawkins/components';
import { spacings, fontSizes } from '@hawkins/variables';

import { TranslationNamespace } from 'types';
import { Page } from 'components/Page';
import { TwoColumn } from 'components/layout';
import { PageTitle } from 'components/text';
import { NavigationButton } from 'components/navigation';
import { gotoPortal } from 'utils';
import { useAnalytics, useLocaleIcons } from 'hooks';

export default function Home() {
  const { t } = useTranslation(TranslationNamespace.LEP);
  const { t: contentful } = useTranslation(TranslationNamespace.CONTENTFUL);
  const { logSignInClick, logContactUsClick } = useAnalytics();
  const router = useRouter();
  const { IconArrowRight } = useLocaleIcons();

  useEffect(() => {
    router.prefetch('/dashboard');
  }, []);

  return (
    <Page>
      <TwoColumn>
        <Box>
          <PageTitle />
          <div
            dangerouslySetInnerHTML={{
              __html: contentful('lepHomePage_description'),
            }}
            style={{ fontSize: fontSizes.fontSize4 }}
          />

          <Text bold level={2}>
            {t('new_user')}
          </Text>
          <Text gutterBottom={spacings.space5} level={2}>
            {t('please_create_account')}
          </Text>

          <NavigationButton href="/register" icon={IconArrowRight}>
            {t('create_an_account')}
          </NavigationButton>

          <Text gutterTop={spacings.space5} level={2}>
            {t('returning_user')}{' '}
            <TextLink
              onClick={() => {
                logSignInClick();
                gotoPortal();
              }}
              style={{ fontSize: fontSizes.fontSize4 }}
              variant="standalone"
            >
              {t('sign_in')}
            </TextLink>
          </Text>

          <Text gutterTop={spacings.space5} level={2}>
            {t('questions')}{' '}
            <TextLink
              href="mailto:leportalsupport@legal.netflix.com"
              onClick={logContactUsClick}
              style={{ fontSize: fontSizes.fontSize4 }}
              variant="standalone"
            >
              {t('contact_us')}
            </TextLink>
          </Text>
        </Box>
      </TwoColumn>
    </Page>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  