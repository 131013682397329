import { Box } from '@hawkins/components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Children } from 'types';

export type TwoColumnProps = Children;

export function TwoColumn({ children }: TwoColumnProps) {
  const theme = useTheme();
  const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box
      display="grid"
      gridAutoFlow={largerThanMd ? 'column' : 'row'}
      gridTemplateColumns={largerThanMd ? '1fr 1fr' : 'auto'}
      placeItems="flex-start"
    >
      {children}
    </Box>
  );
}
